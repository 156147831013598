import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Transforming <span>Complex Data </span> <br />Into  <span>Actionable Insights</span></h2>
      {/* Unleashing the power of data to unlock endless possibilities */}
      <div>I am a passionate data science professional with over 4 years of experience in working with data and 2+ years of professional experience as a Data Analyst. As a freelancer and blogger, I am dedicated to exploring the latest trends and technologies in the field of data science. You can find all of my projects on my GitHub profile, and I am always eager to collaborate on exciting new projects. Don't hesitate to get in touch with me to learn more about my work or to discuss potential collaborations.</div>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
