import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
const SocialMedia = () => {
  const handleGithubClick = () => {
    window.open('https://github.com/panchalsagar1815', '_blank');
  };

  const handleLinkedinClick = () => {
    window.open('https://www.linkedin.com/in/sagar-ppanchal', '_blank');
  };
return (
  <div className="app__social">
    <div onClick={handleLinkedinClick}>
      <FaLinkedinIn />
    </div>
    <div onClick={handleGithubClick}>
      <FaGithub />
    </div>
    <div>
      <BsInstagram />
    </div>
  </div>
  );
};
export default SocialMedia;
